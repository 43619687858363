.breadcrumbs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    list-style: none;
}

.breadcrumbs li {
    margin: 0px !important;
    position: relative;
}


.breadcrumbs li:not(:first-child)::before {
    content: '/';
    position: absolute;
    left: -18px;
    font-weight: 600;
}

.breadcrumbs li a {
    font-weight: 600;
}