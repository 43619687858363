/* @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800;900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,200;0,400;0,900;1,100;1,200;1,300;1,500;1,900&family=Open+Sans:wght@400;500;600;700;800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,200;0,400;0,900;1,100;1,200;1,300;1,500;1,900&family=Open+Sans:wght@400;500;600;700;800&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,200;0,400;0,900;1,100;1,200;1,300;1,500;1,900&family=Open+Sans:wght@400;500;600;700;800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */
/* @import url('https://fonts.cdnfonts.com/css/gumela'); */
:root {
  --color-1: #1074BE;
  --color-1-transparent: rgba(16, 115, 190, 0.30);
  --color-1-dark: #044577;
  --color-2: #F9AA29;
  --color-3: #4b91f1;
  --color-4: #a4b4ba;
  --font:  'Open Sans', sans-serif;
  --header: 100px;
  /* --border-color: rgba(100, 111, 117, 0.24); */
  --border-color: #EAF2F3;
}

*{
   /* font-family: 'Fira Sans', sans-serif; */
font-family: 'Open Sans', sans-serif; 
/* font-family: 'Roboto', sans-serif; */
/* font-family: 'Gumela'; */
                                                
}
body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: 0 0;
  position: absolute
}

body::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: var(--color-2);
}

/*--------------------------------------------------------------
# Colors
--------------------------------------------------------------*/
.color-1 {
  color: var(--color-1);
}

.color-2 {
  color: var(--color-2);
}

.bg-1 {
  background-color: var(--color-1);
}

.bg-2 {
  background-color: var(--color-2);
}

/*--------------------------------------------------------------
# Selection
--------------------------------------------------------------*/
:-moz-selection {
  background: var(--color-2);
  color: #fff !important;
}

::-webkit-selection {
  background: var(--color-2);
  color: #fff !important;
}

::-o-selection {
  background: var(--color-2);
  color: #fff !important;
}

::-ms-selection {
  background: var(--color-2);
  color: #fff !important;
}

::selection {
  background: var(--color-2);
  color: #fff !important;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
